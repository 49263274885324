.btn {
  border-radius: 4px;
  font-family: 'Laro-Bold';
  font-weight: 700;
  width: 700;
  letter-spacing: 0.24em;
  line-height: 32px;
  box-shadow: none !important;
  padding-top: 12px !important;
  padding-bottom: 12px !important;

  font-size: 18px;

  border: none !important;

  .loader {
    border: 4px solid #f3f3f3; /* Light grey */
    border-top: 4px solid var(--ps-sunsetsand);
    border-radius: 50%;
    width: 32px;
    height: 32px;
    margin-left: 1rem;
    margin-right: 1rem;
    animation: spin 2s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @media (max-width: 1200px) {
    &.btn {
      padding-top: 6px !important;
      padding-bottom: 6px !important;
      font-size: 14px;
    }
  }

  &.btn-primary {
    color: var(--ps-paper);
    background-color: var(--ps-evergreen);
    border: 2px solid var(--ps-evergreen) !important;
    &:hover {
      background-color: var(--ps-dark-evergreen);
    }

    &:focus {
      border: 2px solid var(--ps-sage) !important;
    }
  }

  &.btn-secondary {
    color: var(--ps-evergreen);
    background-color: var(--ps-paper);
    border: 2px solid var(--ps-paper) !important;

    &:hover {
      background-color: var(--ps-hover-sage);
    }

    &:focus {
      background-color: var(--ps-hover-sage);
      border: 2px solid var(--ps-sage) !important;
    }
  }

  &.btn-tertiary {
    color: var(--ps-paper);
    background-color: transparent;
    border: 2px solid var(--ps-paper) !important;

    &:hover {
      border: 2px solid var(--ps-light-sage) !important;
    }
  }
}
