.form-field {
  input,
  textarea {
    font-size: 20px;
    line-height: 26px;
    min-height: 60px;
    padding: 16px;
    background-color: var(--ps-paper);
    border: 1px solid var(--ps-light-sage);
    outline: none;
    border-radius: 4px;

    &:active,
    &:hover,
    &:focus {
      border-color: var(--ps-evergreen);
    }

    &:valid[data-valid='true'] {
      border-color: var(--ps-valid);
    }

    &[data-touched='true']:invalid,
    &[data-touched='true'][data-valid='false'] {
      border-color: var(--ps-invalid);
      background-color: var(--ps-invalid-hover);
    }
  }

  textarea {
    min-height: 90px;
  }

  .error-message {
    display: none;
    font-weight: bold;
    color: var(--ps-invalid);

    small {
      margin-top: 4px;
    }
  }

  input:not(:focus):invalid[data-touched='true'] ~ .error-message,
  input:not(:focus)[data-valid='false'] ~ .error-message,
  textarea:not(:focus):invalid[data-touched='true'] ~ .error-message,
  textarea:not(:focus)[data-valid='false'] ~ .error-message {
    display: flex;
  }
}

@media (max-width: 1200px) {
  .form-field {
    input,
    textarea {
      font-size: 15px;
      line-height: 24px;
    }
  }
}
