@media (max-width: 992px) {
  .quote-main-container {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .quote-image {
    height: 54px !important;
  }
}

.quote {
  opacity: 70%;
}

.quote-image {
  height: 78px;
  border-radius: 39px;
  border-width: 4px;
  border-style: solid;
  border-color: var(--ps-paper);
}
