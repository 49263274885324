.contact-segment-picker {
    align-self: flex-start;

    .btn {
        padding: 10px 25px !important;

        &:hover {
            color: var(--ps-light-sage);
        }
    }
}