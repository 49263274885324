.header-container {
  position: relative;
  overflow: hidden;

  .header-image {
    height: 300px;

    img {
      bottom: -50px;
      right: 0;
    }
  }
}

.header-background-image {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.header-text-container {
  padding-top: 150px;

  .bracket-content {
    color: var(--ps-paper);
    position: relative;

    &::before {
      color: var(--ps-sunsetsand);
      content: '[';
      margin-left: -25px;
      margin-right: 4px;
    }

    &::after {
      color: var(--ps-sunsetsand);
      content: ']';
      margin-left: 4px;
    }

    span.h1 {
      display: inline-flex;
      margin: 0;

      &.home {
        width: 14.5ch;
      }

      &.project {
        width: 6.6ch;
      }
    }

    .bracket-end {
      position: absolute;
      bottom: 0;
    }
  }

  .header-logo {
    display: none;
  }

  .header-btns {
    display: flex;
  }
}

@media (max-width: 1200px) {

  .header-btns,
  .header-image {
    display: none !important;
  }

  .bracket-content {
    &::before {
      margin-left: -15px !important;
    }

    span.h1 {
      line-height: 47px !important;
    }
  }
}

@media (max-width: 1070px) {
  .header-logo {
    display: flex !important;
  }

  .header-hero-sub-text-container {
    text-align: center;
  }

  .header-hero-content-container {
    justify-content: center;

    .header-hero-text-container {
      &.services {
        width: 92%;
      }

      &.contact {
        width: 82%;
      }
    }
  }

  .header-hero-text-container {
    justify-content: center;

    .bracket-content {
      text-align: center;

      &::before {
        margin-left: -15px !important;
      }

      .h1 {
        padding-left: 0 !important;
      }
    }
  }
}