.step-container {
  .step-number-container {
    span.h1 {
      font-weight: 600;
      margin: 0;
    }

    .underscore {
      bottom: 8px;
    }
    .step-number-content-container {
      border-radius: 4px;
      width: 80%;
    }
  }

  p {
    white-space: pre-line;
  }
}

@media (max-width: 1200px) {
  .step-container {
    .step-number-container {
      .underscore {
        bottom: 4px;
      }
    }
  }
}

@media (min-width: 767px) {
  .step-container {
    .step-title {
      background-color: transparent !important;
      padding: 0 !important;
    }
  }
}

@media (max-width: 766px) {
  .step-container {
    padding: 0 !important;

    .step-number-container {
      display: none;
    }

    .step-content-container {
      padding: 0 !important;

      .step-title,
      p {
        text-align: center;
      }

      p {
        margin: 20px 10px;
      }
    }
  }
}
