.corner {
  position: absolute;
  width: 32px;
  height: 32px;

  &.corner-start-start,
  &.corner-start-end {
    top: 0;
    border-top: 4.5px solid;
  }

  &.corner-end-start,
  &.corner-end-end {
    bottom: 0;
    border-bottom: 4.5px solid;
  }

  &.corner-start-start,
  &.corner-end-left {
    left: 0;
    border-left: 4.5px solid;
  }

  &.corner-start-end,
  &.corner-end-end {
    right: 0;
    border-right: 4.5px solid;
  }
}
