.contact-card-0,
.contact-card-4 {
  padding-left: 48px;
  padding-right: 48px;
}

.contact-card-0 {
  border-radius: 0;
}
.contact-card-4 {
  border-radius: 4px;
}
.contact-card-text {
  min-height: 70px;
}

@media (min-width: 1200px) {
  .contact-card-items {
    padding: 3rem !important;
    margin-right: 3rem !important;
    margin-left: 3rem !important;

    &.p-0 {
      padding: 0 !important;
    }
    &.m-0 {
      margin: 0 !important;
    }
  }
}

@media (max-width: 1200px) {
  .contact-card-items {
    margin: 40px 0;
    .btn {
      width: 75%;
      max-width: 400px;
    }
  }
  // On smaller devices where we wish the contact cards to stack vertically, there should be no radius or background.
  .contact-card-0,
  .contact-card-4 {
    background-color: transparent !important;
    padding-left: 24px;
    padding-right: 24px;
  }
  .contact-card-4 {
    border-radius: 0;
  }
}
