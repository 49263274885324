.side-container-image {
  img {
    max-height: 580px !important;
    -webkit-box-shadow: 0px 16px 16px -16px rgba(66, 66, 60, 0.3);
    box-shadow: 0px 16px 16px -16px rgba(66, 66, 60, 0.3);
  }
}

.body-text {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

@media (max-width: 1200px) {
  .side-container-text {
    p {
      padding-top: 24px !important;
      padding-bottom: 24px !important;
    }
  }
}

@media (max-width: 992px) {
  .side-image-container {
    text-align: center;

    .side-container-text {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    .side-container-image {
      padding: 0 !important;
    }

    .side-container-button {
      display: none;
    }
  }

  .side-image-container {
    .row {
      flex-direction: column-reverse;
    }
  }
}
