.image-badge-container {
  position: absolute;
  bottom: -65px;

  .image-badge {
    height: 78px;
    width: auto;

    &.clutch-badge {
      box-shadow: none;
      height: 180px;
      transition: 500ms;
      margin: 0 5px;
    }
  }
}

@media (max-width: 1400px) {
  .image-badge {
    height: 78px;
    width: auto;

    &.clutch-badge {
      transform: scale(0.7);
      margin: 0 -20px !important;
    }
  }
}

@media (max-width: 992px) {
  .image-badge {
    height: 78px;
    width: auto;

    &.clutch-badge {
      transform: scale(0.7);
      margin: 0 -20px !important;
    }
  }
}