.card-text {
  text-align: center;
}

.service-card-image-container {
  width: 300px;
}

.card-shadow {
  -webkit-box-shadow: 0px 16px 16px -16px rgba(66, 66, 60, 0.3);
  box-shadow: 0px 16px 16px -16px rgba(66, 66, 60, 0.3);
}
