.service-card-container {
  width: 70%;
}

.service-card-row {
  p {
    opacity: 100%;
  }

  a {
    font-size: 20px;
    line-height: 26px;
    text-decoration: underline;
    text-transform: none;
    font-weight: normal;
    letter-spacing: 0.01em;
    opacity: 100%;
    color: var(--ps-black);

    font-family: 'Open Sans', sans-serif;

    &:hover {
      color: var(--ps-black);
      opacity: 70%;
    }
  }
}

@media (max-width: 1500px) {
  .service-card-container {
    width: 100%;
  }
}

@media (max-width: 1200px) {
  .service-card-row {
    a {
      font-size: 15px;
      line-height: 24px;
    }
  }
}
