.company-logo {
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 4px;
  max-width: 192px;

  &:hover {
    background-color: var(--ps-dark-sage-hover);
  }
}
