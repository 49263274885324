.steps-container {
  width: 70%;
}

@media (max-width: 1400px) {
  .steps-container {
    width: 85%;
  }
}

@media (max-width: 1140px) {
  .steps-container {
    width: 100%;
  }
}
