.underscore {
  // display: flex;
  // align-items: end;
  position: relative;

  &.us-sunsetsand::after {
    background-color: var(--ps-sunsetsand);
  }

  &.us-sage::after {
    background-color: var(--ps-sage);
  }
  .underscore-hide::after {
    width: 0;
    height: 0;
    background-color: transparent;
  }

  &::after {
    content: '';
    width: 12px;
    height: 4.5px;
    position: absolute;
    bottom: 0;
    margin-left: 10px;
  }
}

@media (max-width: 1200px) {
  .underscore::after {
    width: 8px;
    height: 2.5px;
  }
}
