.success-message,
.error-message {
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    opacity: 100%;
    margin-bottom: 0;
  }
}

.success-message {
  background-color: var(--ps-hover-sage);
}

.error-message {
  background-color: var(--ps-invalid-hover);
}