.underscore-container h3::after {
  content: '';
  background: blue;
  width: 30px;
  height: 10px;
}

@media (max-width: 992px) {
  .project-card-main-container {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }
}
