.legal-content-container {
  display: flex;
  justify-content: center;

  .legal-content {
    width: 48%;

    h1 {
      color: black !important;
      line-height: normal;
    }

    h2 {
      color: black !important;
      line-height: normal;
    }

    h3 {
      color: var(--ps-light-evergreen) !important;
      line-height: normal;
    }

    h4 {
      color: var(--ps-light-evergreen) !important;
      line-height: normal;
    }

    p {
      line-height: normal;
      font-family: 'Open Sans', sans-serif;
      font-size: 20px;
      line-height: 30px;
      letter-spacing: 0.01em;
      text-transform: none;
      color: var(--ps-light-evergreen);
    }

    a {
      line-height: normal;
      font-family: 'Open Sans', sans-serif;
      font-size: 20px;
      line-height: 30px;
      letter-spacing: 0.01em;
      text-transform: none;
      color: var(--ps-light-evergreen);
      text-decoration: underline;
    }

    ul {
      padding-left: 2em;
    }
  }
}

@media (max-width: 1200px) {
  .legal-content-container {
    .legal-content {
      width: 100%;
    }

    .legal-content p,
    .legal-content a {
      font-size: 15px;
      line-height: 24px;
    }
  }
}