.contact-card-underscore-container {
  padding: 0 20px;

  .underscore::after {
    right: -10px;
  }
}

@media (width < 1200px) {
  .contact-card-underscore-container {
    padding: 0 15px;
  }
}
