.project-card-container {
  height: 100%;

  &:focus {
    .project-card {
      border: 2px solid var(--ps-dark-sage);
    }
  }

  .project-card {
    height: 100%;
    .image-container {
      img {
        border-radius: 4px;
      }
    }
  }

  p {
    font-weight: 400;
    text-transform: none !important;
  }
}

@media (max-width: 1200px) {
  .project-card-container {
    .project-card {
      .image-container {
        img {
          max-height: 350px !important;
        }
      }
    }
  }
}
