@import '/src/components/Symbol/Underscore/Underscore.scss';

:root {
  --ps-black: #141615;

  --ps-dark-evergreen: #001d12; // evergreen-700
  --ps-dark-evergreen-hover: #001d1c3d;
  --ps-evergreen: #002c1c; // evergreen-500
  --ps-light-evergreen: #0f4632; // evergreen-300

  --ps-dark-sage: #9dd188; // sage-700
  --ps-dark-sage-hover: #9dd18829; //sage-700 with 16% opacity
  --ps-sage: #c4dfb9; // sage-500
  --ps-light-sage: #e4f2de; // sage-300
  --ps-hover-sage: #f3f9f1; // hover

  --ps-dark-sunsetsand: #f0b183; // sunsetsand-700
  --ps-sunsetsand: #fbcaa6; // sunsetsand-500
  --ps-light-sunsetsand: #f8e2d1; // sunsetsand-300

  --ps-paper: #ffffff; // paper

  --ps-valid: #00893f;
  --ps-invalid: #ff3b53;
  --ps-invalid-hover: #ff3b531f;
}

* {
  margin-top: 0;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
ul {
  margin: 0;
  padding: 0;
}

h1,
.h1 {
  font-size: 70px;
  font-family: 'Laro-Light';
  line-height: 74px;
  letter-spacing: 0;
  color: var(--ps-paper);
  text-transform: lowercase;
  font-style: 'normal';
  opacity: 1;
}

h2,
.h2 {
  font-size: 32px;
  font-family: 'Open Sans', sans-serif;
  line-height: 42px;
  letter-spacing: 0.01em;
  color: var(--ps-paper);
  opacity: 1;
}

h3,
.h3 {
  font-size: 18px;
  font-family: 'Laro-Bold';
  line-height: 32px;

  letter-spacing: 0.24em;
  color: var(--ps-paper);
  text-transform: uppercase;
  opacity: 1;
}

h4,
.h4 {
  font-size: 18px;
  font-family: 'Laro-Bold';
  line-height: 32px;

  letter-spacing: 0.24em;
  color: var(--ps-paper);
  text-transform: uppercase;
  opacity: 1;
}

a,
button {
  font-size: 16px;
  font-family: 'Laro';
  font-weight: 600;
  line-height: 29px;
  text-transform: uppercase;
  color: var(--ps-paper);
  text-decoration: none;
  letter-spacing: 0.24em;

  &:hover {
    color: var(--ps-paper);
  }
}

p,
input,
textarea,
ul {
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0.01em;
  opacity: 70%;
  color: var(--ps-evergreen);
}

small {
  font-size: 14px;
  line-height: 16px;
  opacity: 70%;
  letter-spacing: 6%;
}

p,
small,
input,
textarea,
ul {
  font-family: 'Open Sans', sans-serif;
}

.container-xxl {
  max-width: 1920px;
}

@media (max-width: 1200px) {

  h1,
  .h1 {
    font-size: 40px;
    line-height: 42px;
    letter-spacing: 0;
  }

  h2,
  .h2 {
    font-size: 24px;
    line-height: 32px;
  }

  h3,
  .h3 {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.24em;
  }

  h4,
  .h4 {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.24em;
  }

  p {
    font-size: 15px;
    line-height: 24px;
  }

  small {
    line-height: 18px;
  }
}

.bold {
  font-weight: 600;
}

.hide {
  display: none;
  margin: 0 !important;
  padding: 0 !important;
}

//Colours
.bg-black {
  background-color: var(--ps-blank);
}

.bg-dark-evergreen {
  background-color: var(--ps-dark-evergreen);
}

.bg-evergreen {
  background-color: var(--ps-evergreen);
}

.bg-light-evergreen {
  background-color: var(--ps-light-evergreen);
}

.bg-dark-sage {
  background-color: var(--ps-dark-sage);
}

.bg-sage {
  background-color: var(--ps-sage);
}

.bg-light-sage {
  background-color: var(--ps-light-sage);
}

.bg-hover-sage {
  background-color: var(--ps-hover-sage);
}

.bg-dark-sunsetsand {
  background-color: var(--ps-dark-sunsetsand);
}

.bg-sunsetsand {
  background-color: var(--ps-sunsetsand);
}

.bg-light-sunsetsand {
  background-color: var(--ps-light-sunsetsand);
}

.bg-paper {
  background-color: var(--ps-paper);
}

.f-black {
  color: var(--ps-blank);
}

.f-dark-evergreen {
  color: var(--ps-dark-evergreen);
}

.f-evergreen {
  color: var(--ps-evergreen);
}

.f-light-evergreen {
  color: var(--ps-light-evergreen);
}

.b-dark-sage {
  color: var(--ps-dark-sage);
}

.f-sage {
  color: var(--ps-sage);
}

.f-light-sage {
  color: var(--ps-light-sage);
}

.f-dark-sunsetsand {
  color: var(--ps-dark-sunsetsand);
}

.f-sunsetsand {
  color: var(--ps-sunsetsand);
}

.f-light-sunsetsand {
  color: var(--ps-light-sunsetsand);
}

.f-paper {
  color: var(--ps-paper);
}

.b-black {
  border-color: var(--ps-black) !important;
}

.b-dark-evergreen {
  border-color: var(--ps-dark-evergreen) !important;
}

.b-evergreen {
  border-color: var(--ps-evergreen) !important;
}

.b-light-evergreen {
  border-color: var(--ps-light-evergreen) !important;
}

.b-dark-sage {
  border-color: var(--ps-dark-sage) !important;
}

.b-sage {
  border-color: var(--ps-sage) !important;
}

.b-light-sage {
  border-color: var(--ps-light-sage) !important;
}

.b-dark-sunsetsand {
  border-color: var(--ps-dark-sunsetsand) !important;
}

.b-sunsetsand {
  border-color: var(--ps-sunsetsand) !important;
}

.b-light-sunsetsand {
  border-color: var(--ps-light-sunsetsand) !important;
}

.b-paper {
  border-color: var(--ps-paper) !important;
}