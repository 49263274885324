.social-icon {
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: var(--ps-dark-sage-hover);
    border-radius: 4px;
  }
}
