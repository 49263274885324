.icon-container {
  position: absolute;
  bottom: -15px;

  .icon {
    border-radius: 45px;
    -webkit-box-shadow: 0px 16px 16px -16px rgba(66, 66, 60, 0.3);
    box-shadow: 0px 16px 16px -16px rgba(66, 66, 60, 0.3);
    height: 78px;
    width: 78px;
  }
}
