.navigation {
  position: fixed;
  top: 0;
  width: 100;
  z-index: 100;
  transition: 0.4s;
  border: 1px solid transparent;

  &.navigation-bg:not(.navigation-mobile) {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    background-color: var(--ps-evergreen);
    transition: 0.4s;
    border-bottom-color: var(--ps-dark-evergreen);
    box-shadow: 0px 4px 16px 0px var(--ps-dark-evergreen-hover);
  }

  .mobile-menu-container {
    position: relative;
    z-index: 1000;
  }

  .nav-logo-container {
    .company-logo {
      height: 95px;
      transition: 0.2s;
    }
  }

  .nav-logo-container.nav-logo-scroll {
    .nav-mobile {
      img {
        opacity: 1 !important;
        transition: 0.2s;
      }

      .company-logo {
        height: 45px;
      }
    }

    .company-logo {
      height: 60px;
      max-width: 110px;
    }
  }

  .nav-logo-container {
    &:not(.logo-visible) {
      .nav-mobile img {
        opacity: 0;
        transition: 0.2s;
      }
    }
  }

  .nav-logo-container.nav-logo-scroll .nav-mobile img {
    opacity: 1 !important;
    transition: 0.2s;
  }
}

.navigation,
.footer-nav-items {
  .nav-link {
    border-radius: 4px;
    font-family: 'Laro-SemiBold';
    font-weight: 600;
    border: 2px solid transparent;

    .nav-link-line {
      width: 10px;
      display: block;
      border-top: 2px solid transparent;
    }

    &.nav-selected {
      .bt-paper {
        border-top-color: var(--ps-paper) !important;
      }

      .bt-sage {
        border-top-color: var(--ps-sage) !important;
      }
    }

    &:hover {
      background-color: var(--ps-dark-sage-hover);
    }

    &:focus {
      border: 2px solid var(--ps-sage) !important;
      outline: 0px; // removes default focus outline added by Chrome
    }

    &:hover,
    &:focus {
      &.f-paper {
        color: var(--ps-paper);
      }

      &.f-sage {
        color: var(--ps-sage);
      }
    }
  }

  .nav-logo-container {
    padding-left: 0;
  }

  .nav-desktop.nav-items-container {
    padding-right: calc(var(--bs-gutter-x) * 0.2);
  }

  .navbar-toggler {
    padding: 10px;
    border: 2px solid transparent;

    &:focus {
      border-color: var(--ps-paper);
      box-shadow: none;
    }

    &:hover {
      background-color: var(--ps-dark-sage-hover);
    }

    .navbar-toggler-icon {
      width: 16px;
      height: 16px;
      display: flex;
      justify-content: center;
    }
  }

  .nav-mobile {
    display: none;
  }
}

#navbarToggleMobile {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  padding-top: 7rem !important;
  padding-left: 2.5rem !important;
  padding-right: 2.5rem !important;
  overflow-y: auto;

  &.hide {
    display: none;
  }

  .project-image {
    height: 25vh;
  }
}

@media (min-width: 1400px) and (max-width: 1650px) {
  .navigation {
    .nav-logo-container {
      width: 40%;
    }

    .nav-desktop.nav-items-container {
      width: 60%;
    }
  }
}

@media (min-width: 1200px) and (max-width: 1400px) {
  .navigation {
    .nav-logo-container {
      width: 30%;
    }

    .nav-desktop.nav-items-container {
      width: 70%;
    }
  }
}

@media (max-width: 1070px) {
  .navigation {
    padding-left: 40px !important;
    padding-right: 40px !important;

    .navigation-container {
      padding-right: 0.5rem !important;
      padding-left: 0.5rem !important;
    }

    .nav-desktop {
      display: none;
    }

    .nav-mobile {
      display: inline-flex !important;
    }
  }
}

@media (min-height: 850px) {
  .navigation {
    #navbarToggleMobile {
      .recent-project {
        display: flex !important;
      }
    }
  }
}
