.quote-container {
  justify-content: center;

  &.quote-end {
    .quote {
      transform: scaleX(-1) scaleY(-1);
    }
  }
}

@media (max-width: 960px) {
  .quote-container {
    .quote-img {
      height: 48px;
    }
    &.quote-start {
      justify-content: start;
    }

    &.quote-end {
      justify-content: end;
    }
  }
}
