.service-content-container {
  width: 50%;
}

@media (max-width: 960px) {
  .service-content-container {
    width: 100%;
  }

  .service-container {
    padding: 0 !important;
  }
}
