.text-banner-container {
    width: 70%;

    .text-banner-title{
        h1, .h1, h3, .h3{
            margin: 0;
        }
    }
}

@media (max-width: 1200px) {
    .text-banner-main-container {
        padding-left: 20px !important;
        padding-right: 20px !important;

        .text-banner-container{
            width: 100%;
        }
    }
}