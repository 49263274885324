.service-icon-main-container {
  padding-top: 120px;

  .service-icon-container {
    width: 80%;

    .icon-container-content {
      margin: 0 !important;
      padding-top: 80px;
    }

    .icon-container-title {
      width: 80%;
    }

    .icon-img {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;

      min-width: 80px;

      img {
        padding: 10px;

        min-width: 80px;
        min-height: 80px;
        max-width: 80px;
        max-height: 80px;
      }
    }

    .icon-text-container {
      padding: 10px 20px;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
}

@media (max-width: 1200px) {
  .service-icon-main-container {
    padding: 0 10px;
    padding-top: 72px;

    .service-icon-container {
      width: 90%;

      .icon-container-content {
        padding-top: 48px;
      }

      .icon-container-title {
        width: 90%;
      }
    }
  }
}

@media (max-width: 600px) {
  .service-icon-main-container {
    padding: 0 5px;
    padding-top: 72px;

    .service-icon-container {
      width: 100%;

      .icon-container-content {
        padding-top: 48px;
      }
    }

    .icon-container-content {
      padding: 0 24px;
    }
  }
}
