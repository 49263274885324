.footer-container {
  .footer-image-item {
    img {
      padding: 0 1rem;
    }

    &.badge-icon img {
      height: 160px;
    }
  }

  .footer-nav-items,
  .footer-social-container,
  .footer-social-items,
  .footer-company-info-container {
    justify-content: start;
  }

  .footer-company-info-container {
    small {
      text-align: lefty;
    }
  }
}

@media (max-width: 992px) {
  .footer-container {
    padding-left: 0;
    padding-right: 0;

    .footer-nav-items {
      flex-direction: column !important;
    }

    .footer-image-item,
    .footer-nav-items,
    .footer-social-container,
    .footer-social-items,
    .footer-company-info-container {
      justify-content: center !important;
    }

    .footer-company-info-container {
      flex-direction: column-reverse !important;

      small {
        text-align: center;
      }
    }
  }
}